import { useRef, useEffect, useState } from 'react';
import {
  Flex,
  Link,
  Text,
  Tooltip,
  Icon,
  IconButton,
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  LinkBox,
  LinkOverlay,
  Heading,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useDisclosure,
  useDimensions,
  SkeletonText,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import CsvDownloader from 'react-csv-downloader';
import {
  FiArrowRight, FiArrowLeft, FiGrid, FiList, FiDownload, FiMoreHorizontal,
} from 'react-icons/fi';
import GaugeChart from 'react-gauge-chart';
import { reportsList } from '@src/modules/regional-reporting/api/reports-list';

function MoreDrawer({ isLoading, children }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef();
  return (
    <>
      <Tooltip label="More Information">
        <IconButton ref={btnRef} onClick={onOpen} icon={<FiMoreHorizontal />} borderRadius="full" colorScheme="gray" ml={3} isDisabled={isLoading} />
      </Tooltip>
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={btnRef}
        size="xl"
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            More Information
          </DrawerHeader>
          <DrawerBody>
            {children}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}

export default function Pagination({ currentReportUrl, isLoading, reportData = {} }) {
  const { isOpen: isReportListOpen, onOpen: onOpenReportList, onClose: onCloseReportList } = useDisclosure();
  const ref = useRef();
  const dimension = useDimensions(ref, true);
  const enabledReportsList = reportsList.filter((x) => x.enabled);
  const { links, moreInfo, name } = reportsList.find((x) => x.url === currentReportUrl);

  const prevIndex = enabledReportsList.findIndex((report) => report.url === currentReportUrl) - 1;
  const nextIndex = enabledReportsList.findIndex((report) => report.url === currentReportUrl) + 1;

  const nextReport = nextIndex !== enabledReportsList.length ? enabledReportsList[nextIndex] : enabledReportsList[0];
  const prevReport = prevIndex !== -1 ? enabledReportsList[prevIndex] : enabledReportsList[enabledReportsList.length - 1];

  const [csvData, setCsvData] = useState([]);

  useEffect(() => {
    if (reportData.data) {
      const csv = Array.isArray(reportData?.data) ? reportData?.data?.map(({
        id, ...keep
      }) => keep) : Object.fromEntries(Object.entries(reportData?.data).map(({
        id, ...keep
      }) => keep));
      setCsvData(csv);
    }
  }, [reportData.data]);

  const isScrolling = dimension?.borderBox.top === 80;

  return (
    <Box
      position="sticky"
      top="80px"
      pt={isScrolling ? 2 : 0}
      pb={4}
      px={4}
      mx="-16px"
      background="gray.50"
      zIndex="200"
      ref={ref}
      boxShadow={isScrolling ? '1px 7px 10px -9px rgba(0,0,0,0.15);' : 'none'}
    >
      <Flex align="center" justify="space-between" mb={4}>
        <Flex align="center" justify="space-between">
          <Tooltip label="Back to Dashboard">
            <IconButton
              as={RouterLink}
              to="/regional-reporting/transport-outcomes"
              borderRadius="full"
              colorScheme="gray"
              color="gray.600"
              icon={<FiGrid />}
            />
          </Tooltip>
          <Text fontSize="sm" fontWeight="bold" color="gray.400" ml={4}>Regional Reporting</Text>
        </Flex>
        <Flex align="center" justify="space-between">
          {prevReport
            && (
            <Tooltip label={prevReport.name}>
              <Link
                as={RouterLink}
                to={`/regional-reporting/transport-outcomes/${prevReport.url}`}
                color="brand.orange.500"
                display="flex"
                align="center"
                borderRadius="sm"
                p={2}
                _hover={{
                  textDecoration: 'none',
                  color: 'white',
                  background: 'brand.orange.500',
                }}
              >
                <Icon as={FiArrowLeft} boxSize={8} />
              </Link>
            </Tooltip>
            )}
          {nextReport
            && (
            <Tooltip label={nextReport.name}>
              <Link
                as={RouterLink}
                to={`/regional-reporting/transport-outcomes/${nextReport.url}`}
                color="brand.orange.500"
                display="flex"
                align="center"
                borderRadius="sm"
                p={2}
                _hover={{
                  textDecoration: 'none',
                  color: 'white',
                  background: 'brand.orange.500',
                }}
              >
                <Icon as={FiArrowRight} boxSize={8} />
              </Link>
            </Tooltip>
            )}
          <Tooltip label="Reports List">
            <IconButton onClick={onOpenReportList} icon={<FiList />} borderRadius="full" colorScheme="gray" ml={3} />
          </Tooltip>
          {Array.isArray(csvData)
            ? (
              <CsvDownloader
                filename={`${reportData.rcaName} - ${reportData.reportName} (${reportData?.lockYear?.replace('/', '-')})`}
                extension=".csv"
                separator=","
                datas={csvData}
                isDisabled={isLoading}
              >
                <Tooltip label="Download Report CSV">
                  <IconButton icon={<FiDownload />} borderRadius="full" colorScheme="gray" ml={3} isDisabled={reportData.data?.length === 0 || isLoading} />
                </Tooltip>
              </CsvDownloader>
            ) : (
              <Menu>
                <MenuButton
                  as={IconButton}
                  aria-label="Options"
                  icon={<FiDownload />}
                  borderRadius="full"
                  colorScheme="gray"
                  ml={3}
                />
                <MenuList>
                  {Object.entries(csvData).map((item) => {
                    const dataName = item[0].charAt(0).toUpperCase() + item[0].slice(1).replace(/([A-Z])/g, ' $1');
                    return (
                      <CsvDownloader
                        key={item[0]}
                        filename={`${reportData.rcaName} - ${reportData.reportName} - ${dataName} (${reportData?.lockYear?.replace('/', '-')})`}
                        extension=".csv"
                        separator=","
                        datas={item[1]}
                        isDisabled={isLoading}
                      >
                        <MenuItem icon={<FiDownload />}>{`Download ${dataName} CSV`}</MenuItem>
                      </CsvDownloader>
                    );
                  })}
                </MenuList>
              </Menu>
            )}
          <MoreDrawer isLoading={isLoading}>
            {moreInfo
              && (
                moreInfo
              )}
            {reportData?.dataConfidence > 0
              && (
                <>
                  <Heading as="h3" fontSize="lg" my={4}>Data Confidence</Heading>
                  <Flex alignSelf="flex-start" background="gray.50" borderRadius="md" p={4}>
                    <Flex direction="row" alignItems="center">
                      <GaugeChart
                        nrOfLevels={4}
                        arcPadding={0.025}
                        cornerRadius={2}
                        percent={reportData.dataConfidence / 100}
                        colors={['#cccccc', '#999999', '#666666', '#333333']}
                        hideText
                        style={{ width: 150 }}
                      />
                      <Box>
                        <Text fontWeight="bold" fontSize="sm" lineHeight="1" mb={1}>{reportData.rcaName}</Text>
                        <Text fontWeight="normal" fontSize="sm">
                          {reportData.dataConfidence}
                          %
                        </Text>
                      </Box>
                    </Flex>
                  </Flex>
                </>
              )}
            {links?.length > 0
              && (
              <Box mt={4}>
                <Heading as="h3" fontSize="lg" mb={2}>Relevant Links</Heading>
                {links.map((link) => (
                  <Link
                    href={link.url}
                    fontSize="md"
                    key={link.url}
                    display="block"
                    py={1}
                    isExternal={link.isExternal}
                  >
                    {link.title}
                  </Link>
                ))}
              </Box>
              )}
          </MoreDrawer>
        </Flex>
      </Flex>
      <SkeletonText noOfLines={1} skeletonHeight="8" isLoaded={!isLoading} width={isLoading ? '20%' : 'auto'}>
        <Heading as="h1">
          {name}
          {' '}
          {reportData?.lockYear}
        </Heading>
      </SkeletonText>
      <Modal isOpen={isReportListOpen} onClose={onCloseReportList} size="xl">
        <ModalOverlay
          bg="blackAlpha.700"
          backdropFilter="blur(4px)"
        />
        <ModalContent>
          <ModalHeader>Regional Reports</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {enabledReportsList.map((report) => (
              <LinkBox
                as="article"
                p={3}
                borderRadius="md"
                key={report.url}
                border="2px solid"
                borderColor={report.url === currentReportUrl ? 'brand.orange.500' : 'gray.50'}
                backgroundColor={report.url === currentReportUrl ? 'brand.orange.500' : 'white'}
                mb={4}
                transition="all 200ms"
                _hover={{ borderColor: 'brand.orange.500' }}
              >
                <Heading size="sm">
                  <LinkOverlay
                    as={RouterLink}
                    to={`/regional-reporting/transport-outcomes/${report.url}`}
                    onClick={onCloseReportList}
                    color={report.url === currentReportUrl ? 'white' : 'gray.800'}
                    fontWeight="normal"
                    textDecoration="none"
                  >
                    {report.name}
                  </LinkOverlay>
                </Heading>
              </LinkBox>
            ))}
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
}
