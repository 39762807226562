import { useState, useEffect } from 'react';
import {
  Heading, Container, Grid, GridItem, Text, Box, Flex,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Divider,
  ListIcon,
  ListItem,
  List,
  Icon,
  HStack,
  Circle,
  Collapse,
} from '@chakra-ui/react';
import { isEmpty, groupBy } from 'lodash';
import MainLayout from '@src/components/layouts/main';
import EmptyState from '@src/components/shared/EmptyState';
import LoadingSpinner from '@src/components/shared/LoadingSpinner';
import { usePlanningQualityPillars, usePlanningQuality } from '@src/modules/performance/context/reports-api-hooks';
import Pagination from '@src/modules/performance/components/Pagination';
import {
  FiCheck, FiAward, FiChevronUp, FiChevronDown,
} from 'react-icons/fi';
import { TrafficLightColours } from '../components/charts/ChartColours';

function calculateColor(score) {
  const high = 2.25;
  const mid = 1.5;
  if (score > high) {
    return TrafficLightColours.GREEN;
  }
  if (score > mid) {
    return TrafficLightColours.YELLOW;
  }
  if (score != null) {
    return TrafficLightColours.ORANGE;
  }
  return TrafficLightColours.GREY;
}

function calculateAverageScore(values) {
  const divideBy = values.length;
  return parseFloat(values.reduce((acc, obj) => acc + obj.rcaScore, 0) / divideBy).toFixed(2);
}

function ProgressBar({ color, value, ...rest }) {
  const percent = (value / 3) * 100;
  return (
    <Flex position="relative" align="center" {...rest}>
      <Flex
        bgColor={color}
        w={14}
        h={6}
        borderRadius="full"
        fontSize="xs"
        fontWeight="bold"
        color="white"
        zIndex="100"
        align="center"
        justify="center"
        alignSelf="normal"
        border="2px solid"
        borderColor="white"
      >
        {value ?? 'N/A'}
      </Flex>
      <Box backgroundColor="gray.100" height={2} position="relative" borderRadius="full" overflow="hidden" w="100%" zIndex="50" ml="-8px">
        <Box w={`${percent}%`} bgColor={color} height="100%" position="absolute" />
      </Box>
    </Flex>
  );
}

function AssessmentItem({ item }) {
  const [show, setShow] = useState(false);
  const handleToggle = () => setShow(!show);
  return (
    <Box
      shadow="sm"
      background="white"
      borderRadius="lg"
      mb={4}
      border="1px solid"
      borderColor="transparent"
      _hover={{
        borderColor: show ? null : 'brand.orange.400',
        shadow: show ? null : 'md',
      }}
    >
      <Box display="block" p={3} width="100%" textAlign="left" onClick={handleToggle} _hover={{ cursor: 'pointer' }}>
        <Flex direction="row" width="100%" align="center" justify="space-between" mb={2}>
          <Text fontSize="md" fontWeight="bold">{item.assessmentCriteria}</Text>
          <Icon as={show ? FiChevronDown : FiChevronUp} />
        </Flex>
        <ProgressBar color={calculateColor(item.rcaScore)} value={item.rcaScore} w="100%" />
      </Box>
      <Collapse in={show}>
        <Box p={3}>
          <Grid
            gap={4}
            templateColumns="auto 1fr"
            alignItems="center"
          >
            <GridItem colSpan={2}>
              <Box position="relative">
                <Flex background="white" zIndex="100" display="inline-flex" position="relative" borderRadius="sm" direciton="row" align="center" pr={4}>
                  <Icon as={FiAward} boxSize={6} mr={2} />
                  <Text as="span" fontSize="sm" fontWeight="bold">Exemplar RCAs</Text>
                </Flex>
                <Divider position="absolute" top="50%" left="0" marginTop="-4px" borderBottom="1px solid" borderBottomColor="gray.200" zIndex="50" />
              </Box>
            </GridItem>
            <Text fontSize="sm">
              {item.regionalName}
              {' '}
              <Text as="span" color="gray.400" fontSize="xs">
                (
                {`Within ${item.region} region`}
                )
              </Text>
            </Text>
            <ProgressBar color={calculateColor(item.regionalScore)} value={item.regionalScore} w="100%" />
            <Text fontSize="sm">
              {item.nationalName}
              {' '}
              <Text as="span" color="gray.400" fontSize="xs">
                (National)
              </Text>
            </Text>
            <ProgressBar color={calculateColor(item.nationalScore)} value={item.nationalScore} w="100%" />
            <Text fontSize="sm">
              {item.peerGroupName}
              {' '}
              <Text as="span" color="gray.400" fontSize="xs">
                (
                {`Within ${item.peerGroup}`}
                )
              </Text>
            </Text>
            <ProgressBar color={calculateColor(item.peerGroupScore)} value={item.peerGroupScore} w="100%" />
          </Grid>
          <Box border="2px solid" borderColor="gray.200" p={6} borderRadius="md" mt={6}>
            <Text mb={4} fontWeight="bold" fontSize="md">Good Practices</Text>
            <List spacing={3}>
              {item.goodPractice.split('????????? ').map((x, i) => (x.length > 0 ? (
                <ListItem key={i} fontSize="sm" display="flex">
                  <ListIcon as={FiCheck} color="green.600" boxSize={5} mr={4} />
                  <Text>{x}</Text>
                </ListItem>
              ) : null))}
            </List>
          </Box>
        </Box>
      </Collapse>
    </Box>
  );
}

function PlanningQualityPillars() {
  const { data, isLoading, error } = usePlanningQualityPillars();
  const { data: achievement } = usePlanningQuality();
  const [selectedPillar, setSelectedPillar] = useState(null);
  const errorStatus = error?.response?.status;

  const sortedData = Object.entries(groupBy(data?.sort((a, b) => a.pillar.localeCompare(b.pillar)), 'pillar')).map(([k, v]) => (
    {
      pillarName: k,
      rcaAverage: calculateAverageScore(v),
      data: [...v.sort((a, b) => a.rcaScore - b.rcaScore)],
    }
  ));

  useEffect(() => {
    setSelectedPillar(sortedData[0]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (isLoading) {
    return (
      <MainLayout>
        <Container maxW="full">
          <LoadingSpinner />
        </Container>
      </MainLayout>
    );
  }

  if (errorStatus === 401) {
    return (
      <MainLayout>
        <Container maxW="full">
          <EmptyState
            title="Access Denied"
            message="You do not have permission to view this page."
          />
        </Container>
      </MainLayout>
    );
  }

  if (!isLoading && data && isEmpty(data)) {
    return (
      <MainLayout>
        <Container maxW="full">
          <EmptyState
            title="Unknown Error"
            message="Oops something went wrong, please try again later."
          />
        </Container>
      </MainLayout>
    );
  }

  return (
    <MainLayout backgroundColor="gray.50">
      <Container maxW="full">
        <Pagination
          currentReportUrl="activity-management"
          breadcrumbs={(
            <Breadcrumb my={4} ml={4} fontSize="xs">
              <BreadcrumbItem>
                <BreadcrumbLink href="/performance/dashboard">Dashboard</BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbItem>
                <BreadcrumbLink href="/performance/reports/activity-management">Activity Management</BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbItem isCurrentPage>
                <BreadcrumbLink href="#">Planning Quality Pillars</BreadcrumbLink>
              </BreadcrumbItem>
            </Breadcrumb>
        )}
        >
          <Box>
            <Heading>
              Planning Quality Pillars
            </Heading>
            <Flex justify="space-between" align="center" my={4} p={3} border="1px solid" borderColor="gray.200" borderRadius="md">
              <HStack>
                <Circle size={10} bg={calculateColor(achievement?.value)} mr={1} color="white" fontSize="xs" fontWeight="bold">
                  {achievement?.value}
                </Circle>
                <Text fontWeight="bold" fontSize="lg">Overall Score</Text>
              </HStack>
              <HStack spacing={3}>
                <HStack spacing={2}>
                  <Circle size="12px" bg={TrafficLightColours.GREEN} />
                  <Text fontSize="xs">Good (&gt;2.25)</Text>
                </HStack>
                <HStack spacing={2}>
                  <Circle size="12px" bg={TrafficLightColours.YELLOW} />
                  <Text fontSize="xs">Fit for purpose (&gt;1.5 to 2.25)</Text>
                </HStack>
                <HStack spacing={2}>
                  <Circle size="12px" bg={TrafficLightColours.ORANGE} />
                  <Text fontSize="xs">Room for improvement (&lt;=1.5)</Text>
                </HStack>
                <HStack spacing={2}>
                  <Circle size="12px" bg={TrafficLightColours.GREY} />
                  <Text fontSize="xs">Not assessed</Text>
                </HStack>
              </HStack>
            </Flex>
          </Box>
        </Pagination>
        <Grid
          gap={8}
          templateColumns="1fr 4fr"
          position="relative"
        >
          <GridItem>
            <Box position="sticky" top="280px">
              {sortedData.map((item) => (
                <Box
                  key={item.pillarName}
                  mb={4}
                  background={item.pillarName === selectedPillar?.pillarName ? 'white' : 'gray.50'}
                  shadow="sm"
                  borderRadius="md"
                  p={3}
                  border="1px solid"
                  borderColor={item.pillarName === selectedPillar?.pillarName ? 'brand.orange.400' : 'gray.200'}
                  _hover={{
                    borderColor: item.pillarName === selectedPillar?.pillarName ? null : 'brand.orange.400',
                    cursor: item.pillarName === selectedPillar?.pillarName ? null : 'pointer',
                  }}
                  onClick={() => { setSelectedPillar(item); window.scrollTo(0, 0); }}
                  position="relative"
                  _before={{
                    content: '""',
                    width: 0,
                    height: 0,
                    position: 'absolute',
                    borderWidth: item.pillarName === selectedPillar?.pillarName ? '9px 0 9px 9px' : null,
                    borderColor: 'transparent transparent transparent var(--chakra-colors-brand-orange-400)',
                    right: '-9px',
                    top: '50%',
                    transform: 'translateY(-50%)',
                  }}
                  _after={{
                    content: '""',
                    width: 0,
                    height: 0,
                    position: 'absolute',
                    borderWidth: item.pillarName === selectedPillar?.pillarName ? '8px 0 8px 8px' : null,
                    borderColor: 'transparent transparent transparent #fff',
                    right: '-8px',
                    top: '50%',
                    transform: 'translateY(-50%)',
                  }}
                >
                  <HStack>
                    <Circle size={10} bg={calculateColor(item.rcaAverage)} mr={1} color="white" fontSize="xs" fontWeight="bold">
                      {item.rcaAverage}
                    </Circle>
                    <Text fontWeight="bold" fontSize="lg" mb={1}>{item.pillarName}</Text>
                  </HStack>
                </Box>
              ))}
            </Box>
          </GridItem>
          <GridItem>
            <Text fontWeight="bold" fontSize="2xl" mb={4}>{selectedPillar?.pillarName}</Text>
            {
              selectedPillar?.data.map((item, idx) => (
                <AssessmentItem key={`${item.assessmentCriteria}_${idx}`} item={item} />
              ))
            }
          </GridItem>
        </Grid>
      </Container>
    </MainLayout>
  );
}

export default PlanningQualityPillars;
