import {
  Flex,
} from '@chakra-ui/react';
import { QUARTERS } from '@src/modules/performance/shared/constants';

const badgeStyles = {
  border: '1px solid',
  borderColor: 'gray.200',
  color: 'gray.600',
  fontSize: '12px',
  textAlign: 'center',
  fontWeight: 'bold',
  py: '1px',
  px: 2,
  borderRadius: 'full',
  flexDirection: 'row',
  align: 'center',
  cursor: 'default',
  minWidth: 'auto',
  flexShrink: 0,
};

function LockYearBadge({ lockYear, quarter }) {
  if (!lockYear) {
    return <Flex {...badgeStyles} visibility="hidden">None</Flex>;
  }

  return (
    <Flex align="center" justify="center">
      <Flex {...badgeStyles}>
        {lockYear}
      </Flex>
      {quarter && (
        <Flex {...badgeStyles} ml={1}>
          {QUARTERS.find((q) => q.id === parseInt(quarter, 10))?.label}
        </Flex>
      )}
    </Flex>
  );
}

export default LockYearBadge;
