import { useState, useRef } from 'react';
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
} from '@chakra-ui/react';

// Custom hook to programmatically trigger a confirm dialog with custom header and message
export function useConfirm() {
  const [resolvePromise, setResolvePromise] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef();

  const [header, setHeader] = useState('');
  const [message, setMessage] = useState('');
  const [confirmButton, setConfirmButton] = useState('Confirm');

  const confirm = ({ title, body, confirmButtonLabel }) => new Promise((resolve) => {
    setHeader(title);
    setMessage(body);
    setConfirmButton(confirmButtonLabel);
    setResolvePromise(() => resolve);
    onOpen();
  });

  const handleConfirm = () => {
    resolvePromise(true);
    onClose();
  };

  const handleCancel = () => {
    resolvePromise(false);
    onClose();
  };

  function ConfirmDialog() {
    return (
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={handleCancel}
        size="lg"
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader>{header || 'Are you sure?'}</AlertDialogHeader>
            <AlertDialogBody>{message || 'Are you sure you want to proceed?'}</AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={handleCancel} colorScheme="gray">
                Cancel
              </Button>
              <Button colorScheme="orange" onClick={handleConfirm} ml={3}>
                {confirmButton || 'Ok'}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    );
  }

  return { confirm, ConfirmDialog };
}
