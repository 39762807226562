import { TrafficLightColours as COLOURS, coassuranceAssessmentLegend } from '@src/modules/performance/shared/constants';
import { VStack, Text, SimpleGrid } from '@chakra-ui/react';
import Widget from './Widget';
import { CircleIndicator } from '../Indicators';
import { useInvestmentAuditKPI, useTechnicalAuditKPI } from '../../context/reports-api-hooks';
import LockYearBadge from '../LockYearBadge';
import { mapCoassuranceToText } from '../../shared/helpers';
import IndicatorLegend from '../IndicatorLegend';

const mapColour = (value) => {
  const colours = ['GREY', 'RED', 'ORANGE', 'YELLOW', 'GREEN'];
  return colours[value * 4];
};

export default function CoinvestorAssuranceWidget() {
  const { data: investmentAuditKpi, isLoading: investmentAuditKpiLoading, isError: investmentAuditKpiError } = useInvestmentAuditKPI();
  const { data: technicalAuditKpi, isLoading: technicalAuditKpiLoading, isError: technicalAuditKpiError } = useTechnicalAuditKPI();

  const isTechnical = investmentAuditKpi?.isTechnical;
  const title = 'Co-investor Assurance';
  const description = !isTechnical ? mapCoassuranceToText(technicalAuditKpi?.score) : '';

  const isLoading = investmentAuditKpiLoading && technicalAuditKpiLoading;
  const isError = investmentAuditKpiError || technicalAuditKpiError;

  // If there is an error and no data we want to hide the widget
  if (isError && (!investmentAuditKpi || !technicalAuditKpi)) return null;

  return (
    <Widget
      link="/performance/reports/coinvestor-assurance"
      title={title}
      description={description}
      lockYear={!isTechnical ? technicalAuditKpi?.date : null}
      quarter={null}
      isLoading={isLoading}
      hideDescription={isTechnical}
      moreInfoContent={(
        <>
          <Text fontSize="sm" mb={4}>
            The overall assessment results for procedural and technical audits of the RCA undertaken by NZTA. Results are displayed separately when the two audits are carried out individually, or as a single overall rating for a combined audit.
          </Text>
          <IndicatorLegend items={coassuranceAssessmentLegend} />
        </>
      )}
    >
      {!isTechnical && (
        <CircleIndicator
          size={100}
          colour={COLOURS[mapColour(technicalAuditKpi?.score)]}
          value={null}
          isLoading={isLoading}
        />
      )}
      {isTechnical && (
        <SimpleGrid columns="2">
          <VStack>
            <Text
              textAlign="center"
              fontSize="sm"
              color="gray.600"
              mb={0}
            >
              Procedural
            </Text>
            <LockYearBadge lockYear={investmentAuditKpi?.date} />
            <CircleIndicator
              size={80}
              colour={COLOURS[mapColour(investmentAuditKpi?.score)]}
              value={null}
              isLoading={isLoading}
              padding={0}
            />
            <Text
              textAlign="center"
              fontSize="xs"
              whiteSpace="pre-line"
              color="gray.600"
              wordBreak="break-word"
              px={4}
              lineHeight={1.2}
            >
              {mapCoassuranceToText(investmentAuditKpi?.score)}
            </Text>
          </VStack>
          <VStack>
            <Text
              textAlign="center"
              fontSize="sm"
              color="gray.600"
              mb={0}
            >
              Technical
            </Text>
            <LockYearBadge lockYear={technicalAuditKpi?.date} />
            <CircleIndicator
              size={80}
              colour={COLOURS[mapColour(technicalAuditKpi?.score)]}
              value={null}
              isLoading={isLoading}
              padding={0}
            />
            <Text
              textAlign="center"
              fontSize="xs"
              whiteSpace="pre-line"
              color="gray.600"
              wordBreak="break-word"
              px={4}
              lineHeight={1.2}
            >
              {mapCoassuranceToText(technicalAuditKpi?.score)}
            </Text>
          </VStack>
        </SimpleGrid>
      )}
    </Widget>
  );
}
