// import { Text, VStack } from '@chakra-ui/react';
import Widget from './Widget';
import { useDeliveryKPI } from '../../context/reports-api-hooks';
import { TextIndicator } from '../Indicators';

export default function DeliveryWidget() {
  const { data: deliveryData, isLoading, isError } = useDeliveryKPI();

  const title = 'Transport Programme Delivery';
  const description = 'Approved LTP Funding Spent';

  // If there is an error and no data we want to hide the widget
  if (isError && !deliveryData) return null;

  return (
    <Widget
      link="/performance/reports/delivery"
      title={title}
      description={description}
      lockYear={deliveryData?.lockYear}
      quarter={null}
      isLoading={isLoading && !deliveryData}
    >
      <TextIndicator
        value={deliveryData?.currentPercentage ? `${deliveryData?.currentPercentage}%` : null}
        isLoading={isLoading && !deliveryData}
      />
      {/* <VStack>
        <VStack mb={4}>
          <TextIndicator
            value={deliveryData?.currentPercentage ? `${deliveryData?.currentPercentage}%` : null}
            isLoading={isLoading && !deliveryData}
          />
          <Text textAlign="center" fontSize="xs" whiteSpace="pre-line" color="gray.600">
            Current
            {' '}
            {deliveryData?.budgetPeriod}
            {' '}
            LTP
            <br />
            Approved Funding Spent
          </Text>
        </VStack>
        <VStack>
          <TextIndicator
            value={deliveryData?.originalPercentage ? `${deliveryData?.originalPercentage}%` : null}
            fontSize="xl"
            isLoading={isLoading && !deliveryData}
          />
          <Text textAlign="center" fontSize="xs" whiteSpace="pre-line" color="gray.600">
            Original
            {' '}
            {deliveryData?.budgetPeriod}
            {' '}
            LTP
            <br />
            Approved Funding Spent
          </Text>
        </VStack>
      </VStack> */}
    </Widget>
  );
}
