import {
  Text,
  HStack,
  Switch,
  Input,
  FormControl,
  FormLabel,
  Box,
} from '@chakra-ui/react';
import MockDate from 'mockdate';
import { useIsUserInRole } from '@transport-insights/uikit';
import { useEffect, useState } from 'react';

function TtmDevTools({
  onChangeTestMode, onChangeMockDate,
}) {
  const hasDeveloperRole = useIsUserInRole('Developer');
  const [isTestMode, setIsTestMode] = useState(false);
  const [mockDate, setMockDate] = useState('');

  useEffect(() => {
    if (isTestMode && mockDate) {
      MockDate.set(mockDate);
      onChangeMockDate();
      onChangeTestMode(isTestMode);
    } else if (!isTestMode) {
      MockDate.reset();
      setMockDate('');
      onChangeMockDate();
      onChangeTestMode(isTestMode);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTestMode, mockDate]);

  // Reset mock date when component unmounts
  useEffect(
    () => () => {
      MockDate.reset();
      setMockDate('');
      setIsTestMode(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  if (!hasDeveloperRole) return null;

  return (
    <Box p={4} border="1px solid" borderColor="gray.200" mb={4} borderRadius="md">
      <HStack w="100%" justify="space-between">
        <HStack>
          <Text fontWeight="bold">Developer Tools</Text>
          <Text>
            Todays date is
            {' '}
            { new Date().toLocaleDateString('en-NZ') }
          </Text>
        </HStack>
        <HStack>
          <Text fontSize="sm">Test Mode</Text>
          <Switch isChecked={isTestMode} onChange={() => setIsTestMode(!isTestMode)} />
        </HStack>
      </HStack>
      {isTestMode && (
        <FormControl mt={4}>
          <FormLabel fontSize="sm">Override Current Date</FormLabel>
          <Input type="date" w={44} value={mockDate} onChange={(e) => setMockDate(e.target.value)} />
        </FormControl>
      )}
    </Box>
  );
}

export default TtmDevTools;
