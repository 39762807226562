import { TrafficLightColours as COLOURS, deliveryPerformanceLegend } from '@src/modules/performance/shared/constants';
import { useDeliveryPerformanceKPI } from '@src/modules/performance/context/map-api-hooks';
import { Text } from '@chakra-ui/react';
import Widget from './Widget';
import { CircleIndicator } from '../Indicators';
import { mapValueToColourRange, roundKpiValue } from '../../shared/helpers';
import IndicatorLegend from '../IndicatorLegend';

export default function DeliveryPerformanceWidget() {
  const { data: deliveryPerformanceData, isLoading, isError } = useDeliveryPerformanceKPI();

  const title = 'Road Maintenance Delivery';
  const description = 'Variance (%)';
  const colour = mapValueToColourRange(deliveryPerformanceData?.varianceExclEmergency, 'deliveryPerformance');

  // If there is an error and no data we want to hide the widget
  if (isError && (deliveryPerformanceData?.variance === undefined || !deliveryPerformanceData?.variance)) return null;

  return (
    <Widget
      link="/performance/reports/delivery-performance"
      title={title}
      description={description}
      lockYear={deliveryPerformanceData?.quadLockYear}
      quarter={deliveryPerformanceData?.quarter}
      isLoading={isLoading && !deliveryPerformanceData}
      moreInfoContent={(
        <>
          <Text fontSize="sm" mb={4}>
            The indicator shows the colour grade for the cumulative variance in Approved vs Claimed spend up to the reported quarter in the current NLTP:
          </Text>
          <IndicatorLegend items={deliveryPerformanceLegend} />
        </>
      )}
    >
      <CircleIndicator
        size={90}
        colour={COLOURS[colour]}
        value={deliveryPerformanceData?.varianceExclEmergency !== null && deliveryPerformanceData?.varianceExclEmergency !== undefined ? `${roundKpiValue(deliveryPerformanceData?.varianceExclEmergency, 5, 1)}%` : null}
        isLoading={isLoading}
        padding={0}
      />
    </Widget>
  );
}
