import {
  Center, Skeleton, Text,
} from '@chakra-ui/react';

function TextIndicator({
  value, isLoading, padding, fontSize,
}) {
  const adjustedFontSize = fontSize || value?.length > 5 ? 42 : 56;

  return (
    <Center padding={padding || 2} width="100%">
      {isLoading && <Skeleton height={8} width="80%" />}
      {!isLoading && (
        <Text fontSize={adjustedFontSize} color={value ? 'gray.700' : 'gray.200'} lineHeight={0.5}>
          {value || '—'}
        </Text>
      )}
    </Center>
  );
}

export default TextIndicator;
