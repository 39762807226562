import { extendTheme } from '@chakra-ui/react';
import { baseTheme } from '@reg/ui-theme';

import textStyles from './foundations/textStyles';

const breakpoints = {
  base: '0em', // 0px
  sm: '30em', // ~480px
  md: '48em', // ~768px
  lg: '62em', // ~992px
  xl: '80em', // ~1280px
  '2xl': '96em', // ~1536px
  '3xl': '112em', // ~1792px
  '4xl': '128em', // ~2048px
};

// We will be using the base theme as much as possible to get a consistent look and
// feel across the apps
export default extendTheme({
  textStyles,
  breakpoints,
  styles: {
    global: () => ({
      '.apexcharts-tooltip ul': {
        listStyle: 'none',
      },
      '.fc .fc-daygrid-day-number': {
        textDecoration: 'none',
        color: 'gray.600',
      },
      '.fc .fc-col-header-cell-cushion': {
        textDecoration: 'none',
        color: 'gray.800',
      },
      ':root': {
        '--fc-button-text-color': 'white',
        '--fc-button-bg-color': baseTheme.colors.brand.orange[400],
        '--fc-button-border-color': baseTheme.colors.brand.orange[400],
        '--fc-button-hover-bg-color': baseTheme.colors.brand.orange[500],
        '--fc-button-hover-border-color': baseTheme.colors.brand.orange[500],
        '--fc-button-active-bg-color': baseTheme.colors.brand.orange[500],
        '--fc-button-active-border-color': baseTheme.colors.brand.orange[500],

        '--fc-event-bg-color': baseTheme.colors.brand.orange[400],
        '--fc-event-border-color': baseTheme.colors.brand.orange[400],

        '--fc-today-bg-color': baseTheme.colors.brand.orange[50],
      },
    }),
  },
}, baseTheme);
