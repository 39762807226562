// import { Text, VStack } from '@chakra-ui/react';
import Widget from './Widget';
import { useDeliveryKPI } from '../../context/reports-api-hooks';
import { TextIndicator } from '../Indicators';

export default function RoadMaintenanceWidget() {
  const { data: deliveryData, isLoading, isError } = useDeliveryKPI();

  const title = 'Road Maintenance';
  const description = 'Cost per km';

  // If there is an error and no data we want to hide the widget
  if (isError && !deliveryData) return null;

  return (
    <Widget
      link={null}
      title={title}
      description={description}
      lockYear={deliveryData?.lockYear}
      quarter={null}
      isLoading={isLoading && !deliveryData}
    >
      <TextIndicator
        value="$33,688"
        isLoading={isLoading && !deliveryData}
      />
    </Widget>
  );
}
