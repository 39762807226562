import Widget from './Widget';
import { useRoadNetworkUseKPI } from '../../context/reports-api-hooks';
import { TextIndicator } from '../Indicators';

export default function RoadNetworkUseWidget() {
  const { data: roadNetworkUseData, isLoading, isError } = useRoadNetworkUseKPI();

  const title = 'Road Network Use';
  const description = `VKT Growth\n(${roadNetworkUseData?.averageYearRange} Annual Avg)`;

  // If there is an error and no data we want to hide the widget
  if (isError && !roadNetworkUseData) return null;

  return (
    <Widget
      link="/performance/reports/road-network-use"
      title={title}
      description={description}
      lockYear={roadNetworkUseData?.lockYear}
      quarter={null}
      isLoading={isLoading && !roadNetworkUseData}
    >
      <TextIndicator
        value={roadNetworkUseData?.percentage && roadNetworkUseData?.percentage !== undefined ? `${roadNetworkUseData?.percentage.toFixed(1)}%` : null}
        isLoading={isLoading && !roadNetworkUseData}
      />
    </Widget>
  );
}
