import Widget from './Widget';
import { useNetworkCharacteristicsKPI } from '../../context/reports-api-hooks';
import { TextIndicator } from '../Indicators';

export default function NetworkPhysicalCharacteristicsWidget() {
  const { data: networkPhysicalCharacteristicsData, isLoading, isError } = useNetworkCharacteristicsKPI();

  const title = 'Network Physical\nCharacteristics';
  const description = `Network Length Growth\n(${networkPhysicalCharacteristicsData?.averageYearRange} Annual Avg)`;

  // If there is an error and no data we want to hide the widget
  if (isError && !networkPhysicalCharacteristicsData) return null;

  return (
    <Widget
      link="/performance/reports/network-physical-characteristics"
      title={title}
      description={description}
      lockYear={networkPhysicalCharacteristicsData?.lockYear}
      quarter={null}
      isLoading={isLoading && !networkPhysicalCharacteristicsData}
    >
      <TextIndicator
        value={networkPhysicalCharacteristicsData?.percentage && networkPhysicalCharacteristicsData?.percentage !== undefined ? `${networkPhysicalCharacteristicsData?.percentage.toFixed(1)}%` : null}
        isLoading={isLoading && !networkPhysicalCharacteristicsData}
      />
    </Widget>
  );
}
