import { useState, useEffect } from 'react';

import { useSafetyKPI } from '@src/modules/performance/context/map-api-hooks';
import TextWidget from './TextWidget';

const TITLE = 'Road Safety';
const SUBTEXT1 = 'Fatal & Serious Injuries/Trend';
const SUBTEXT2 = '(5 Year Annual Avg)';

function SafetyIndicator({ selection, setSelection }) {
  const { data: safety } = useSafetyKPI();
  const [value, setValue] = useState('--');
  const [colour, setColour] = useState('GREY');
  const [marker, setMarker] = useState('none');

  useEffect(() => {
    setColour(safety.colour);
    setValue(safety.value);
    setMarker(safety.marker);
  }, [safety]);

  return (
    <TextWidget
      title={TITLE}
      value={value ?? 'No data'}
      lockYear={safety.lockYear}
      additionalValue={`(${safety.totalCrashes} crashes)`}
      colour={colour}
      subtext1={SUBTEXT1}
      subtext2={SUBTEXT2}
      marker={marker}
      selected={selection === 'safety'}
      handleClick={() => {
        setSelection('safety');
      }}
    />
  );
}

export default SafetyIndicator;
