import { TrafficLightColours as COLOURS, safetyLegend } from '@src/modules/performance/shared/constants';
import { useSafetyKPI } from '@src/modules/performance/context/map-api-hooks';
import { Text } from '@chakra-ui/react';
import Widget from './Widget';
import { CircleIndicator } from '../Indicators';
import IndicatorLegend from '../IndicatorLegend';

export default function SafetyWidget() {
  const { data: safetyData, isLoading, isError } = useSafetyKPI();

  const title = 'Road Safety';
  const description = `Average annual fatal & serious injury\n(${safetyData?.averageYearRange})`;

  // If there is an error and no data we want to hide the widget
  if (isError && !safetyData?.value) return null;

  return (
    <Widget
      link="/performance/reports/safety"
      title={title}
      description={description}
      lockYear={safetyData?.lockYear}
      quarter={null}
      isLoading={isLoading && !safetyData}
      moreInfoContent={(
        <>
          <Text fontSize="sm" mb={4}>
            The annual average number of fatal and serious injuries for the last five financial years, including the trend for the same period based on the change on the quantity as below:
          </Text>
          <IndicatorLegend items={safetyLegend} />
        </>
      )}
    >
      <CircleIndicator
        size={100}
        colour={COLOURS[safetyData?.colour]}
        value={safetyData?.value}
        isLoading={isLoading && !safetyData}
      />
    </Widget>
  );
}
