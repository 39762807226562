import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();

const dashboardViewState = atom({
  key: 'dashboardView',
  default: 'basic',
  effects: [persistAtom],
});

const customDashboardLayoutState = atom({
  key: 'customDashboardLayout',
  default: {
    widgetList: [],
    showNetworkStats: true,
  },
  effects: [persistAtom],
});

export {
  dashboardViewState,
  customDashboardLayoutState,
};
