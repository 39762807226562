import {
  Button,
  ButtonGroup,
  HStack,
  Text,
} from '@chakra-ui/react';
import MainLayout from '@src/components/layouts/main/MainLayout';
import { useIsUserInRole } from '@transport-insights/uikit';
import { useState } from 'react';
import TtmResults from './TtmResults';
import TtmReporting from './TtmReporting';

export default function TemporaryTrafficManagement() {
  const hasDeveloperRole = useIsUserInRole('Developer');
  const hasTtmAdminRole = useIsUserInRole('TTMAdmin');
  const [developerView, setDeveloperView] = useState('Results');

  // If the user has the Developer role we let them choose which page to view
  if (hasDeveloperRole) {
    return (
      <MainLayout>
        <HStack px={4} mb={4}>
          <Text fontSize="sm">Developer Links: </Text>
          <ButtonGroup isAttached size="sm" colorScheme="gray">
            <Button onClick={() => setDeveloperView('Results')} variant={developerView === 'Results' ? 'solid' : 'outline'}>RCA Results Entry</Button>
            <Button onClick={() => setDeveloperView('Reporting')} variant={developerView === 'Reporting' ? 'solid' : 'outline'}>Admin Reporting</Button>
          </ButtonGroup>
        </HStack>
        {developerView === 'Results' && (
          <TtmResults />
        )}
        {developerView === 'Reporting' && (
          <TtmReporting />
        )}
      </MainLayout>
    );
  }

  // If the user has the TTM Reporting role, show the TTM Reporting page
  if (hasTtmAdminRole && !hasDeveloperRole) {
    return (
      <MainLayout>
        <TtmReporting />
      </MainLayout>
    );
  }

  // If no roles are found, show results page
  // this will handle authentication errors
  return (
    <MainLayout>
      <TtmResults />
    </MainLayout>
  );
}
