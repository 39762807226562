import { TrafficLightColours as COLOURS, activityAssessmentsLegend } from '@src/modules/performance/shared/constants';
import { useActivityManagementKPI } from '@src/modules/performance/context/map-api-hooks';
import { Text } from '@chakra-ui/react';
import Widget from './Widget';
import { CircleIndicator } from '../Indicators';
import { mapValueToColourRange } from '../../shared/helpers';
import IndicatorLegend from '../IndicatorLegend';

export default function NetworkAssetManagementWidget() {
  const { data: activityManagementData, isLoading, isError } = useActivityManagementKPI();

  const title = 'Network Asset Management\nPlan Delivery';
  const description = 'Improvement Plan Progress';
  const colour = mapValueToColourRange(0, 'activity');

  // If there is an error and no data we want to hide the widget
  if (isError && !activityManagementData) return null;

  return (
    <Widget
      link={null}
      title={title}
      description={description}
      lockYear="2022/23"
      quarter={null}
      isLoading={isLoading && !activityManagementData}
      moreInfoContent={(
        <>
          <Text fontSize="sm" mb={2}>Composite indicator based on a weighted average of the four results within the Activity Management zone.</Text>
          <Text fontSize="sm" mb={4}>Based on a weighting of 30% Planning quality, 30% Co-investment planning quality, 10% Smart buyer self-assessment and 30% Data quality.</Text>
          <IndicatorLegend items={activityAssessmentsLegend} />
        </>
      )}
    >
      <CircleIndicator
        size={100}
        colour={COLOURS[colour]}
        value={null}
        isLoading={isLoading && !activityManagementData}
      />
    </Widget>
  );
}
