const luxon = require('luxon');

const formatDate = (date) => {
  if (!date) return null;
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  };
  return new Intl.DateTimeFormat('en-NZ', options).format(new Date(date));
};

// Find and convert dates in a string to financial/lock years
// accepted inputs:
// somethingthathasnoyearinit
// somethingsomethingsomething{lockyearyyyy/yy}something
// somethingsomethingsomething{yyyy}something
// somethingsomethingsomething{MM-YYYY}something
// somethingsomethingsomething{MMM-yyyy}something
// Output:
// yyyy/yy
function convertToFinancialYear(dateString) {
  let foundDate;
  if (/\d{4}\/\d{2}/.test(dateString)) {
    // lock year
    foundDate = luxon.DateTime.fromFormat(dateString.substring(0, 4), 'yyyy');
    // set it to July
    foundDate = foundDate.set({ month: 7 });
  } else if (/\d{2}-\d{4}/.test(dateString)) {
    // MM-YYYY
    foundDate = luxon.DateTime.fromFormat(dateString, 'MM-yyyy');
  } else if (/\d{1,2}\/\d{2}\/\d{4}/.test(dateString)) {
    // d/MM/YYYY
    foundDate = luxon.DateTime.fromFormat(dateString, 'd/MM/yyyy');
  } else if (/[a-zA-Z]{3}-\d{4}/.test(dateString)) {
    // MMM-YYYY
    foundDate = luxon.DateTime.fromFormat(dateString, 'MMM-yyyy');
  } else if (/\d{4}/.test(dateString)) {
    // yyyy
    foundDate = luxon.DateTime.fromFormat(dateString, 'yyyy');
    // set it to July
    foundDate = foundDate.set({ month: 7 });
  }

  if (!foundDate) return '';

  if (foundDate.month <= 6) {
    return `${foundDate.year - 1}/${foundDate.year.toString().slice(-2)}`;
  }
  return `${foundDate.year}/${(foundDate.year + 1).toString().slice(-2)}`;
}

export { convertToFinancialYear, formatDate };
