import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Text,
  VStack,
  HStack,
  Badge,
  Link,
} from '@chakra-ui/react';

function TtmRcaContacts({
  isOpen, onClose, contacts, rcaName,
}) {
  const handleClose = () => {
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          { rcaName }
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={4}>
          {contacts.length === 0 && (
            <Text>No contacts found</Text>
          )}
          {contacts.map((contact, indx) => (
            <VStack
              key={indx}
              align="flex-start"
              mb={indx !== contacts.length - 1 ? 4 : 0}
              pb={indx !== contacts.length - 1 ? 4 : 0}
              borderBottom={indx !== contacts.length - 1 ? '1px solid' : 'none'}
              borderColor="gray.100"
            >
              <HStack justify="space-between" width="100%">
                <Text fontWeight="bold">
                  {contact.firstName}
                  {' '}
                  {contact.lastName}
                </Text>
                <Badge size="xs">
                  {contact.roles}
                </Badge>
              </HStack>
              {contact.email && (
                <Text>
                  <Link href={`mailto: ${contact.email}`}>{contact.email}</Link>
                </Text>
              )}
              {contact.phone && (
                <Text>
                  P.
                  {' '}
                  {contact.phone}
                </Text>
              )}
              {contact.mobile && (
                <Text>
                  M.
                  {' '}
                  {contact.mobile}
                </Text>
              )}
            </VStack>
          ))}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default TtmRcaContacts;
