import Widget from './Widget';
import { useNetworkAvailabilityKPI } from '../../context/reports-api-hooks';
import { TextIndicator } from '../Indicators';

export default function NetworkAvailabilityAccessibilityWidget() {
  const { data: networkAvailabilityData, isLoading, isError } = useNetworkAvailabilityKPI();

  const title = 'Network Availability\n& Accessibility';
  const description = 'Proportion Network Accessible\nto Class 1 and 50MAX';

  // If there is an error and no data we want to hide the widget
  if (isError && !networkAvailabilityData) return null;

  return (
    <Widget
      link="/performance/reports/network-availability"
      title={title}
      description={description}
      lockYear={networkAvailabilityData?.lockYear}
      quarter={null}
      isLoading={isLoading && !networkAvailabilityData}
    >
      <TextIndicator
        value={networkAvailabilityData?.value && networkAvailabilityData?.value !== undefined ? `${networkAvailabilityData?.value.toFixed(1)}%` : null}
        isLoading={isLoading && !networkAvailabilityData}
      />
    </Widget>
  );
}
