import {
  Box, SkeletonCircle, Flex,
} from '@chakra-ui/react';

function CircleIndicator({
  colour, value, size, isLoading, padding,
}) {
  const fontSize = value?.length > 5 ? 'xl' : '3xl';
  const shouldDisplayValue = value !== null && value !== undefined
    && (typeof value === 'number' || (typeof value === 'string' && !(value.includes('No data') || value.includes('null') || value.includes('NaN'))));
  return (
    <Box padding={padding || 2}>
      {isLoading && <SkeletonCircle size={`${size}px`} />}
      {!isLoading && (
        <Box
          position="relative"
          backgroundColor={colour || 'gray.200'}
          boxSize={`${size}px`}
          borderRadius="full"
        >
          {shouldDisplayValue && (
          <Flex
            position="absolute"
            top="50%"
            left="50%"
            transform="translate(-50%, -50%)"
            color="white"
            fontWeight="bold"
            fontSize={fontSize}
            textShadow="1px 1px 2px rgba(0,0,0,0.2)"
            align="center"
          >
            {value}
          </Flex>
          )}
        </Box>
      )}
    </Box>
  );
}

export default CircleIndicator;
