import { useState, useEffect } from 'react';
import { useAchievementsKPI } from '@src/modules/performance/context/map-api-hooks';
import TextWidget from './TextWidget';

const TITLE = 'Road Condition';
const SUBTEXT = 'Ride Quality STE';

function AmenityConditionIndicator({ selection, setSelection }) {
  const { data: achievement } = useAchievementsKPI();
  const [value, setValue] = useState('--');
  const [colour, setColour] = useState('GREY');
  const [marker, setMarker] = useState('none');

  useEffect(() => {
    setColour(achievement.colour);
    setValue(achievement.value);
    setMarker(achievement.marker);
  }, [achievement]);

  return (
    <TextWidget
      title={TITLE}
      value={value || 'No data'}
      lockYear={achievement.lockYear}
      colour={colour}
      subtext1=""
      subtext2={SUBTEXT}
      marker={marker}
      selected={selection === 'amenityCondition'}
      handleClick={() => {
        setSelection('amenityCondition');
      }}
    />
  );
}
export default AmenityConditionIndicator;
