import { DateTime } from 'luxon';

// Function to calculate the progress of the entry period
export const calculateProgress = (startDate, endDate) => {
  // Parse dates using Luxon
  const now = DateTime.now().startOf('day');
  const start = DateTime.fromISO(startDate).startOf('day');
  const end = DateTime.fromISO(endDate).startOf('day');

  // Calculate total days and days until start and end
  const totalDays = end.diff(start, 'days').days;
  const daysUntilStart = Math.max(0, start.diff(now, 'days').days);
  const daysUntilEnd = Math.max(0, end.diff(now, 'days').days);

  let progress = 0;
  let phase = 'before';

  if (now < start) {
    // Before the entry period starts
    progress = ((totalDays - daysUntilStart) / totalDays) * 100;
    phase = 'before';
  } else if (now >= start && now <= end) {
    // During the entry period
    progress = ((now.diff(start, 'days').days) / totalDays) * 100;
    phase = 'during';
  } else {
    // After the entry period ends
    progress = 100;
    phase = 'after';
  }

  return {
    progress: Math.min(100, Math.max(0, progress)),
    daysUntilStart,
    daysUntilEnd,
    phase,
  };
};
