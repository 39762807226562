import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Text,
  Button,
  VStack,
  useToast,
  Icon,
} from '@chakra-ui/react';
import useTemporaryTrafficManagementApi from '@src/modules/performance/api/useTemporaryTrafficManagementApi';
import { FiDownloadCloud } from 'react-icons/fi';

function TtmGeneratedReports({
  isOpen, onClose, reportList,
}) {
  const api = useTemporaryTrafficManagementApi();
  const toast = useToast();

  const handleClose = () => {
    onClose();
  };

  const handleDownloadReport = async (report) => {
    const fileName = `TTM_Report_${report?.lockYear}_Q${report?.quarter}_(${new Date(report?.createdDate).toLocaleString('en-NZ', { dateStyle: 'medium', timeStyle: 'short' })})`;
    try {
      await api.downloadTtmReport(report?.lockYear, report?.quarter, report?.id)
        .then((res) => {
          const file = new Blob(
            [res],
            { type: 'text/csv;charset=utf-8' },
          );
          const url = URL.createObjectURL(file);
          const link = document.createElement('a');
          link.href = url;
          link.download = `${fileName}.csv`;
          link.click();
        });
    } catch {
      toast({
        title: 'Error',
        description: 'An error occurred while downloading the report.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          Generated Reports
          {' '}
          {reportList?.[0]?.lockYear}
          {' '}
          Q
          {reportList?.[0]?.quarter}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={4}>
          {reportList.length === 0 && (
            <Text>No reports have been generated</Text>
          )}
          {reportList.length > 0 && (
            <VStack spacing={4} align="flex-start">
              {reportList.map((report) => (
                <Button
                  key={report.id}
                  leftIcon={<Icon as={FiDownloadCloud} boxSize={4} />}
                  onClick={() => handleDownloadReport(report)}
                  variant="link"
                  size="sm"
                >
                  {`TTM Report ${report?.lockYear} Q${report?.quarter} (${new Date(report?.createdDate).toLocaleString('en-NZ', { dateStyle: 'medium', timeStyle: 'short' })}).csv`}
                </Button>
              ))}
            </VStack>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default TtmGeneratedReports;
