import { TrafficLightColours as COLOURS, servicePerformanceLegend } from '@src/modules/performance/shared/constants';
import { useEffect, useState } from 'react';
import { Text } from '@chakra-ui/react';
import Widget from './Widget';
import { useServicePerformanceKPI } from '../../context/reports-api-hooks';
import { PieChartIndicator } from '../Indicators';
import IndicatorLegend from '../IndicatorLegend';

const colourMap = (value) => {
  if (value <= -1.0) return 'RED';
  if (value >= 1.0) return 'GREEN';
  if (value >= 0.5) return 'ORANGE';
  return 'GREY';
};

export default function ServicePerformanceWidget() {
  const { data: servicePerformanceData, isLoading, isError } = useServicePerformanceKPI();

  const title = 'Service Performance';
  const description = 'LTP Targets Achieved';

  const [chartColours, setChartColours] = useState(['GREY']);
  const [chartData, setChartData] = useState([1]);

  useEffect(() => {
    if (servicePerformanceData && !isLoading) {
      const sortedKPI = [...servicePerformanceData].sort((a, b) => b.index - a.index);

      const newColours = sortedKPI.map((r) => COLOURS[colourMap(r.index)]);
      const newData = sortedKPI.map((r) => (r.count > 0 ? r.count / 10 : r.count));

      setChartColours(newColours);
      setChartData(newData);
    }
  }, [servicePerformanceData, isLoading]);

  // If there is an error and no data we want to hide the widget
  if (isError && !servicePerformanceData) return null;

  return (
    <Widget
      link="/performance/reports/service-performance"
      title={title}
      description={description}
      lockYear={servicePerformanceData?.[0]?.lockYear}
      quarter={null}
      isLoading={isLoading && !servicePerformanceData}
      moreInfoContent={(
        <>
          <Text fontSize="sm" mb={4}>
            Headline result showing the proportion of targets achieved, partially achieved, or not achieved for the current 3 year LTP period to the end of the reported financial year.
          </Text>
          <IndicatorLegend items={servicePerformanceLegend} />
        </>
      )}
    >
      <PieChartIndicator
        size={100}
        colours={chartColours}
        values={chartData}
        isLoading={isLoading && !servicePerformanceData}
      />
    </Widget>
  );
}
