import { useState } from 'react';

import {
  Grid, GridItem, Heading, Link, Flex, Container, VStack, Select, Box, Text,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react';
import { Link as ReactRouterLink } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { selectedDatabaseIdState } from '@transport-insights/uikit';
import { FiInfo } from 'react-icons/fi';

import { useMapData } from '@src/modules/performance/context/map-api-hooks';
import MainLayout from '@src/components/layouts/main';
import LoadingSpinner from '@src/components/shared/LoadingSpinner';
import NetworkCharacteristics from './components/NetworkCharacteristics';
import MapLegend from './components/MapLegend';
import RCAMap from './components/RCAMap';
import SafetyIndicator from './components/SafetyIndicator';
import AmenityConditionIndicator from './components/AmenityConditionIndicator';
import ActivityManagementIndicator from './components/ActivityManagementIndicator';
import CoInvestorIndicator from './components/CoInvestorIndicator';
import ServicePerformanceIndicator from './components/ServicePerformanceIndicator';
import DataQualityIndicator from './components/DataQualityIndicator';

/* ------------------ */
const selections = {
  safety: {
    title: 'Road Safety',
    link: '/performance/dashboard/safety',
  },
  amenityCondition: {
    title: 'Road Condition',
    link: '/performance/dashboard/amenity-and-condition',
  },
  activity: {
    title: 'Activity Management',
    link: '/performance/dashboard/activity-management',
  },
  coassurance: {
    title: 'Co-Investor Assurance',
    link: '/performance/dashboard/coinvestor-assurance',
  },
  servicePerformance: {
    title: 'Service Performance',
    link: '/performance/dashboard/service-performance',
  },
  dataQuality: {
    title: 'Data Quality',
    link: null,
  },
};

const servicePerformanceFilters = [
  {
    value: 'Road safety',
    label: 'Road safety',
  },
  {
    value: 'Condition of the sealed road network',
    label: 'Condition of the sealed road network',
  },
  {
    value: 'Maintenance of the sealed road network',
    label: 'Maintenance of the sealed road network',
  },
  {
    value: 'Condition of the footpaths within the local road network',
    label: 'Condition of the footpaths within the local road network',
  },
  {
    value: 'Response to service requests',
    label: 'Response to service requests',
  },
];

const dataQualityFilters = [
  {
    value: '',
    label: 'Overall',
  },
  {
    value: 'Accuracy',
    label: 'Accuracy',
  },
  {
    value: 'Completeness',
    label: 'Completeness',
  },
  {
    value: 'Timeliness',
    label: 'Timeliness',
  },
  {
    value: 'High',
    label: 'High Importance',
  },
  {
    value: 'Moderate',
    label: 'Moderate Importance',
  },
  {
    value: 'Low',
    label: 'Low Importance',
  },
  {
    value: 'Network',
    label: 'Network',
  },
  {
    value: 'Asset Inventory',
    label: 'Asset Inventory',
  },
  {
    value: 'Condition',
    label: 'Condition',
  },
  {
    value: 'Maintenance Activity',
    label: 'Maintenance Activity',
  },
  {
    value: 'Demand/Use',
    label: 'Demand/Use',
  },
  {
    value: 'Crash',
    label: 'Crash',
  },
];

const activityFilters = [
  {
    value: 'Overall',
    label: 'Overall',
  },
  {
    value: 'Average_score_Pillars_of_Success',
    label: 'Planning Quality',
  },
  {
    value: 'Average_score_TIO',
    label: 'Co-investment planning quality',
  },
  {
    value: 'Smart_buyer_selfassessment',
    label: 'Smart Buyer',
  },
];

function FilterMenu({ options, selected, handleOnChange }) {
  return (
    <Box position="absolute" zIndex="100" left={2} top={2}>
      <Select defaultValue={selected} onChange={(e) => handleOnChange(e.currentTarget.value)} size="sm">
        {options.map((o, i) => <option key={i} value={o.value}>{o.label}</option>)}
      </Select>
    </Box>
  );
}

function DescriptionModal({ selected }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  let content = '';
  let title = '';
  switch (selected) {
    case 'safety':
      title = 'Road Safety';
      content = (
        <>
          <Text mb={2}>
            The map shows the 5 year trend of Fatal and Serious Injuries for each of the 67 Territorial Authorities in New Zealand.
            The legend explains the relevance of the green, amber and red indicators. See the Road Safety zone within the Performance Dashboard for further details.
          </Text>
          <Text mb={2}>The results exclude crashes on State Highways.</Text>
        </>
      );
      break;
    case 'amenityCondition':
      title = 'Road Condition';
      content = (
        <>
          <Text mb={2}>
            The map shows how vehicle travel on &apos;smooth&apos; sealed roads is trending for each of the 67 Territorial Authorities in New Zealand.
            This indicates the trend of the ride quality experienced by road users over the last 5 years. The legend explains the relevance of the green, amber and red indicators.
            See the Road Condition zone within the Performance Dashboard for further details.
          </Text>
          <Text mb={2}>The trends exclude State Highways.</Text>
        </>
      );
      break;
    case 'activity':
      title = 'Activity Management';
      content = (
        <Text mb={2}>
          The map shows for each of the 67 Territorial Authorities in New Zealand, a composite indicator of their Activity Management Planning results,
          Smart buyer self-assessment, and Data Quality results. See the Activity Management zone within the Performance Dashboard for further details.
        </Text>
      );
      break;
    case 'servicePerformance':
      title = 'Service Performance';
      content = (
        <>
          <Text mb={2}>
            The map shows for the 67 Territorial Authorities in New Zealand, the annual results for the five Roads and Footpaths mandatory non-financial
            performance measures, as published in each Authority’s Annual Report.
          </Text>
          <Text mb={2}>The Levels of Service for the five measures are set individually by Territorial Authorities and will differ across the country.</Text>
          <Text mb={2}>
            You can select each of the five measures from the drop down on the map. See the Service Performance
            zone within the Performance Dashboard for further details.
          </Text>
        </>
      );
      break;
    case 'coassurance':
      title = 'Co-Investor Assurance';
      content = (
        <>
          <Text mb={2}>
            The map shows the results from the latest Local Authority technical investment audits carried out by
            NZTA under Section 95(1)(e)(ii) of the Land Transport Management Act 2003. Two types of audits are typically undertaken, a Technical audit and an Investment audit.
            More recently, in some cases, the two reports are combined and are referred to in the map as an Overall Audit.
          </Text>
          <Text mb={2}>The map also shows the month and year of the latest audit for each Territorial Authority.</Text>
          <Text mb={2}>See the Co-Investor Assurance zone within the Performance Dashboard for further details.</Text>
        </>
      );
      break;
    case 'dataQuality':
      title = 'Data Quality';
      content = (
        <>
          <Text mb={2}>
            The map shows for each of the 67 Territorial Authorities in New Zealand, the percentage of asset management data quality metrics
            achieving the expected standard. You can select the different levels of importance, dimensions and categories using the drop down on the map.
            See the Data Quality Dashboard and Metric Library for further details.
          </Text>
          <Text mb={2}>The results exclude Data Quality results for State Highways.</Text>
        </>
      );
      break;
    default:
      break;
  }

  if (!content) return null;

  return (
    <>
      <IconButton onClick={onOpen} aria-label="Show Description" variant="link" size="md" icon={<FiInfo size={24} />} ml={2} />
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {content}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

function MapPage() {
  // default selection is 'safety'
  const [currentSelection, setCurrentSelection] = useState(null);
  const rcaId = useRecoilValue(selectedDatabaseIdState);
  const [filter, setFilter] = useState({
    servicePerformance: servicePerformanceFilters[0].value,
    dataQuality: dataQualityFilters[0].value,
    activity: activityFilters[0].value,
  });
  const { isLoading } = useMapData();

  const initMap = () => {
    setCurrentSelection('safety');
  };

  const renderFilters = () => {
    switch (currentSelection) {
      case 'servicePerformance':
        return (
          <FilterMenu
            options={servicePerformanceFilters}
            selected={filter.servicePerformance}
            handleOnChange={(val) => setFilter({ ...filter, servicePerformance: val })}
          />
        );
      case 'dataQuality':
        return (
          <FilterMenu
            options={dataQualityFilters}
            selected={filter.dataQuality}
            handleOnChange={(val) => setFilter({ ...filter, dataQuality: val })}
          />
        );
      case 'activity':
        return (
          <FilterMenu
            options={activityFilters}
            selected={filter.activity}
            handleOnChange={(val) => setFilter({ ...filter, activity: val })}
          />
        );
      default:
        return null;
    }
  };

  return (
    <MainLayout backgroundColor="gray.50">
      <Container maxW="full" minHeight="100%">
        <Grid
          templateColumns="200px 1fr 200px"
          gap={8}
          minHeight="100%"
        >
          <GridItem>
            <VStack spacing={3}>
              <SafetyIndicator
                selection={currentSelection}
                setSelection={setCurrentSelection}
              />
              <AmenityConditionIndicator
                selection={currentSelection}
                setSelection={setCurrentSelection}
              />
              <ActivityManagementIndicator
                selection={currentSelection}
                setSelection={setCurrentSelection}
                filter={filter.activity}
                filterLabel={activityFilters.find((x) => x.value === filter.activity).label}
                rcaId={rcaId}
              />
              <CoInvestorIndicator
                selection={currentSelection}
                setSelection={setCurrentSelection}
              />
              <ServicePerformanceIndicator
                selection={currentSelection}
                setSelection={setCurrentSelection}
                filter={filter.servicePerformance}
              />
              <DataQualityIndicator
                selection={currentSelection}
                setSelection={setCurrentSelection}
                filter={filter.dataQuality}
              />
            </VStack>
          </GridItem>

          <GridItem position="relative">
            <Flex direction="column" height="100%">
              {currentSelection && (
                <Flex justifyContent="space-between" align="center">
                  <Heading as="h1" display="flex">
                    {selections[currentSelection].title}
                    <DescriptionModal selected={currentSelection} />
                  </Heading>
                  {rcaId < 1000 && selections[currentSelection].link
                    && (
                    <Link
                      as={ReactRouterLink}
                      to={selections[currentSelection].link}
                    >
                      Show Detail &gt;
                    </Link>
                    )}
                </Flex>
              )}
              <Flex flexGrow="1" position="relative" my={3}>
                {isLoading
                  && (
                  <Box width="100%" height="100%" position="absolute" left="0" top="0" zIndex="150">
                    <LoadingSpinner zIndex="150" height="100%" position="relative" />
                    <Box
                      backgroundColor="gray.100"
                      width="100%"
                      height="100%"
                      position="absolute"
                      left="0"
                      top="0"
                      zIndex="100"
                      opacity=".85"
                    />
                  </Box>
                  )}
                {renderFilters()}
                <Box position="absolute" zIndex="100" right={2} top={2}>
                  <MapLegend selection={currentSelection} filter={filter} />
                </Box>
                <RCAMap initMap={initMap} currentSelection={currentSelection} filter={filter[currentSelection]} />
              </Flex>
            </Flex>
          </GridItem>

          <GridItem>
            <NetworkCharacteristics />
          </GridItem>
        </Grid>
      </Container>
    </MainLayout>
  );
}

export default MapPage;
