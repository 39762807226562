import { useState, useCallback, useEffect } from 'react';
import { GoogleMap, Marker } from '@react-google-maps/api';
import { Flex, Spinner, Text } from '@chakra-ui/react';

const containerStyle = {
  width: '100%',
  height: '250px',
};

function LocationMapSelector({ onLocationChange }) {
  const [userLocation, setUserLocation] = useState(null);
  const [markerPosition, setMarkerPosition] = useState(null);
  const [error, setError] = useState(null);

  // Fetch user location
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const coords = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          setUserLocation(coords);
          setMarkerPosition(coords); // Set initial marker position to user location
          onLocationChange(coords);
        },
        (err) => {
          console.error('Error retrieving location:', err);
          setError(err);
        },
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
  }, [onLocationChange]);

  // Handle map clicks to update marker position
  const onMapClick = useCallback((event) => {
    const newPosition = {
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    };
    setMarkerPosition(newPosition);
    onLocationChange(newPosition);
  }, [onLocationChange]);

  // Handle marker drag to update position
  const onMarkerDragEnd = useCallback((event) => {
    const newPosition = {
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    };
    setMarkerPosition(newPosition);
    onLocationChange(newPosition);
  }, [onLocationChange]);

  // Map options to remove controls
  const mapOptions = {
    streetViewControl: false, // Removes Street View control
    fullscreenControl: false, // Removes Fullscreen control
    mapTypeControl: false, // Removes Map Type control (which includes Satellite view)
  };

  if (error) {
    return (
      <Flex justify="center" align="center" p={8} background="red.50" borderRadius="md">
        <Text color="red.500">Error retrieving location. Please allow location access and refresh the page.</Text>
      </Flex>
    );
  }

  return userLocation && !error ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={userLocation} // Center map on user location
      zoom={15}
      onClick={onMapClick}
      options={mapOptions}
    >
      {markerPosition && (
      <Marker
        position={markerPosition}
        draggable
        onDragEnd={onMarkerDragEnd}
      />
      )}
    </GoogleMap>
  ) : (
    <Flex justify="center" align="center" h="100px" background="gray.50" borderRadius="md">
      <Spinner />
      <Text ml={4} fontSize="sm">Loading your location</Text>
    </Flex>
  );
}

export default LocationMapSelector;
