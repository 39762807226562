import {
  Box, SkeletonCircle,
} from '@chakra-ui/react';
import { Pie } from 'react-chartjs-2';

function PieChartIndicator({
  colours, values, size, isLoading,
}) {
  const data = {
    labels: [],
    datasets: [
      {
        data: values.map((v) => v * 100),
        backgroundColor: colours,
        borderWidth: 1,
      },
    ],
  };
  const options = {
    responsive: false,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        enabled: false,
      },
      datalabels: {
        display: false,
        align: 'end',
        anchor: 'end',
        color: '#333333',
        formatter(value) {
          return `${Math.round(value)}%`;
        },
        font: {
          size: 10,
        },
      },
      legend: {
        display: false,
      },
    },
  };

  return (
    <Box>
      {isLoading && <SkeletonCircle size={`${size}px`} />}
      {!isLoading && (
        <Pie data={data} options={options} width={size} height={size} />
      )}
    </Box>
  );
}

export default PieChartIndicator;
