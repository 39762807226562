import {
  Box, Flex, Text,
} from '@chakra-ui/react';

function SplitCircleIndicator({
  colours, labels, size, isLoading,
}) {
  const loadingColour = '#ccc';

  return (
    <Flex align="center" justify="center" position="relative" width="100%">
      <Box pos="relative" p="16px">
        {/* Circle Slices */}
        {isLoading && (
          <Flex
            bg={`conic-gradient(${loadingColour} 0% 33.33%, ${loadingColour} 33.33% 66.66%, ${loadingColour} 66.66% 100%)`}
            width={`${size}px`}
            height={`${size}px`}
            borderRadius="50%"
            transform="rotate(-60deg)"
          />
        )}
        {!isLoading && colours && (
          <Flex
            bg={`conic-gradient(${colours[0]} 0% 33.33%, ${colours[1]} 33.33% 66.66%, ${colours[2]} 66.66% 100%)`}
            width={`${size}px`}
            height={`${size}px`}
            borderRadius="50%"
            transform="rotate(-60deg)"
          />
        )}
        {/* Slice gaps */}
        <Box
          position="absolute"
          w="1px"
          h={`${size / 2}px`}
          bg="#fff"
          bottom="16px"
          left="50%"
          transform="translateX(-50%) rotate(0deg)"
        />
        <Box
          position="absolute"
          w="1px"
          h={`${size / 2}px`}
          bg="#fff"
          top="16px"
          left="50%"
          transformOrigin="bottom"
          transform="translateX(-50%) rotate(60deg)"
        />
        <Box
          position="absolute"
          w="1px"
          h={`${size / 2}px`}
          bg="#fff"
          top="16px"
          left="50%"
          transformOrigin="bottom"
          transform="translateX(-50%) rotate(-60deg)"
        />
      </Box>
      {/* Labels */}
      {!isLoading && labels && (
      <>
        <Text
          position="absolute"
          textAlign="center"
          fontSize="9px"
          top="5px"
          left="50%"
          transform="translate(-50%, 0px)"
        >
          {labels[0]}
        </Text>
        <Text
          position="absolute"
          right="50%"
          fontSize="9px"
          bottom="16px"
          textAlign="left"
          transformOrigin="center left"
          transform="translateX(180%)"
        >
          {labels[1]}
        </Text>
        <Text
          position="absolute"
          fontSize="9px"
          bottom="16px"
          left="50%"
          textAlign="right"
          transformOrigin="center right"
          transform="translateX(-210%)"
        >
          {labels[2]}
        </Text>
      </>
      )}
    </Flex>
  );
}

export default SplitCircleIndicator;
