import Widget from './Widget';
import { useTerritorialActivityKPI } from '../../context/reports-api-hooks';
import { TextIndicator } from '../Indicators';

export default function TerritorialActivityWidget() {
  const { data: territorialActivityData, isLoading, isError } = useTerritorialActivityKPI();

  const title = 'Territorial Activity';
  const description = `Population Growth\n(${territorialActivityData?.averageYearRange} Annual Avg)`;

  // If there is an error and no data we want to hide the widget
  if (isError && !territorialActivityData) return null;

  return (
    <Widget
      link="/performance/reports/territorial-activity"
      title={title}
      description={description}
      lockYear={territorialActivityData?.lockYear}
      quarter={null}
      isLoading={isLoading && !territorialActivityData}
    >
      <TextIndicator
        value={territorialActivityData?.percentage && territorialActivityData?.percentage !== undefined ? `${territorialActivityData?.percentage.toFixed(1)}%` : null}
        isLoading={isLoading && !territorialActivityData}
      />
    </Widget>
  );
}
